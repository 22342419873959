import { useEffect } from 'react';
import useAdultContentHandlers from './useAdultContentHandlers';
import { useSelector } from 'react-redux';
import { useAuth0Wrapper } from './auth0';

const isAdultUser = (dateOfBirth) => {
  if (!dateOfBirth) return undefined;
  const userAge = new Date().getFullYear() - new Date(dateOfBirth).getFullYear();
  return userAge >= 18;
};

const useAdultContentInit = () => {
  const { blockAdultContent } = useAdultContentHandlers();
  const profile = useSelector(({ profile }) => profile);
  const { isAuthenticated } = useAuth0Wrapper();

  const isAdult = isAdultUser(profile?.dateOfBirth);
  useEffect(() => {
    if (isAuthenticated && profile && isAdult === false) {
      //profile loaded and user is not adult
      blockAdultContent();
      return;
    }
  }, [blockAdultContent, isAdult, profile, isAuthenticated]);
};

export default useAdultContentInit;
