import { useCallback } from 'react';
import { useUserProfile } from './useUserProfile';
import { getValidResponseBody } from '../api';
import { migrateProfileWishlist } from './wishlistMigration';
import { useDispatch, useSelector } from 'react-redux';
import { userApi } from '../api';
import { setAnonymousRecentlyViewedProducts } from '../state/anonymousRecentlyViewedProducts/anonymousRecentlyViewedProducts';
import { setAnonymousLikedProducts } from '../state/anonymousLikedProducts/anonymousLikedProducts';
import { useIntl } from 'react-intl';

export const useProfileInit = (
  tokenClaims,
  signupRequired,
  setSignupRequired,
  auth0User,
  setReduxProfile,
  logoutExpiredSession
) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { getUserData, setUserData } = useUserProfile(tokenClaims?.__raw);
  const anonymousRecentlyViewedProducts = useSelector(
    ({ anonymousRecentlyViewedProducts }) => anonymousRecentlyViewedProducts
  );
  const anonymousLikedProducts = useSelector(({ anonymousLikedProducts }) => anonymousLikedProducts);

  const setRecentlyViewProductsAnonymous = useCallback(
    (recentlyViewedProducts) => {
      dispatch(setAnonymousRecentlyViewedProducts(recentlyViewedProducts));
    },
    [dispatch]
  );

  const setAnonymousWishlist = useCallback(
    (wishlist) => {
      dispatch(setAnonymousLikedProducts(wishlist));
    },
    [dispatch]
  );

  const updateBasicDataFromAuth0 = (auth0User) => (profile) => {
    const updatedFields = ['email', 'family_name', 'given_name', 'picture'].reduce((acc, field) => {
      if (auth0User[field] === profile[field]) return acc;
      else return { ...acc, [field]: auth0User[field] };
    }, {});

    if (!Object.keys(updatedFields)?.length) return null;
    return {
      ...profile,
      ...updatedFields,
    };
  };

  const updatePUGChats = (profile) => {
    const anonymousChats = JSON.parse(localStorage?.getItem('pocChatIds') || '[]');
    if (!anonymousChats.length) return null;

    const pocChatData = profile?.pocChatData || [];
    localStorage.removeItem('pocChatIds');
    localStorage.removeItem('pocChatId');
    return {
      ...profile,
      pocChatData: [...pocChatData, ...anonymousChats],
    };
  };

  const updateRVP = useCallback(
    (profile) => {
      if (!anonymousRecentlyViewedProducts.length) return null;
      setRecentlyViewProductsAnonymous([]);
      localStorage.removeItem('rVP');
      return {
        ...profile,
        recentlyViewedProducts: [...profile.recentlyViewedProducts, ...anonymousRecentlyViewedProducts],
      };
    },
    [anonymousRecentlyViewedProducts, setRecentlyViewProductsAnonymous]
  );

  const mutateUserProfile = useCallback(
    (profile) => {
      const operations = [migrateProfileWishlist, updateBasicDataFromAuth0(auth0User), updateRVP, updatePUGChats];
      const updates = operations.reduce(
        (acc, operation) => {
          const processedProfile = operation(acc.profile);
          if (!processedProfile) return acc;
          return { present: true, profile: processedProfile };
        },
        { present: false, profile }
      );

      if (updates.present) return updates.profile;
      else return null;
    },
    [auth0User, updateRVP]
  );

  /**
   * Transfers anonymous liked products to profile
   * @returns bool isTransferExecuted
   */
  const saveAnonymousWishlistToProfile = useCallback(
    async (anonymousLikedProducts) => {
      // save updated profile in user api
      if (!anonymousLikedProducts?.length) return false;
      //todo: use a separate endpoint to transfer anonymously liked products to user profile all at once
      await Promise.all(
        anonymousLikedProducts.map(({ productId }) =>
          userApi.addWishlistProduct({
            //Use endpoint exported from userApi directly
            body: JSON.stringify({ id: productId }),
            headers: { Authorization: `Bearer ${tokenClaims?.__raw}` },
            method: 'POST',
          })
        )
      );
      setAnonymousWishlist([]);
      localStorage.removeItem('wishlistItems');
      return true;
    },
    [setAnonymousWishlist, tokenClaims?.__raw]
  );

  const processProfile = useCallback(
    async (setReduxProfileSyncedWithApi) => {
      const newUserImitation = localStorage.getItem('newUserImitation');
      const nuiRegistered = localStorage.getItem('nuiRegistered');
      if (newUserImitation && !nuiRegistered) {
        setSignupRequired(true);
        return;
      }

      const response = await getUserData();

      if (response.ok) {
        const responseProfile = getValidResponseBody(response);

        if (responseProfile === null && !signupRequired) {
          setSignupRequired(true);
          //user created in auth0, but not on our end
          //engage registration script
          return;
        }
        //signup script is in progress, profile not ready yet
        if (responseProfile === null && signupRequired) return;
        //signup completed successfully and profile is ready
        if (responseProfile && signupRequired) setSignupRequired(false);

        const mutatedUserProfile = mutateUserProfile(responseProfile);

        if (mutatedUserProfile) {
          //user profile should be updated remotely
          setUserData({ body: JSON.stringify(mutatedUserProfile) }).then(async () => {
            const anonymousWishlistTransfered = await saveAnonymousWishlistToProfile(anonymousLikedProducts);
            if (!anonymousWishlistTransfered) setReduxProfileSyncedWithApi();
          });
          return;
        } else {
          const anonymousWishlistTransfered = await saveAnonymousWishlistToProfile(anonymousLikedProducts);
          if (anonymousWishlistTransfered) return;
          //after saveAnonymousWishlistToProfile being invoked getProfileData should be triggered again from useEffect
          setReduxProfileSyncedWithApi();
          setReduxProfile(responseProfile); //remote profile is up to date
        }
      } else {
        console.error('Profile fetch failed');
        logoutExpiredSession(
          intl.formatMessage({
            id: 'notifications.loginFailed',
            defaultMessage: 'Login failed. Please, try again later.',
          })
        );
      }
      //todo: handle failed login attempt
    },
    [
      anonymousLikedProducts,
      getUserData,
      intl,
      logoutExpiredSession,
      mutateUserProfile,
      saveAnonymousWishlistToProfile,
      setReduxProfile,
      setSignupRequired,
      setUserData,
      signupRequired,
    ]
  );

  return {
    processProfile,
  };
};
