/**
 * Parses JSON string
 * @param {jsonString}
 * @returns parsed value or undefined on systax error
 */
//todo: use parseValidJSON instead of JSON.parse, but check carefully how data is used in condition statements
export const parseValidJSON = (jsonString) => {
  let response = undefined;

  try {
    response = JSON.parse(jsonString);
  } catch (e) {
    response = undefined;
  }

  return response;
};
