import React, { useEffect, useState, useRef } from 'react';
import { isProduction } from './isProduction';

//we load components in advance to minimize flickering
const RENDERING_VIEWPORT_THRESHOLD = 50; //value in pixels

const shouldComponentBeFetched = (placeholder, firstRender = false) => {
  const pageScrolltop = document.documentElement.scrollTop;
  //We need this to support components without custom placeholders
  //Built-in placeholder has 1px height
  //We recommend to create custom placeholders for your components
  //because otherwise all of them will be loaded at a time on scroll
  const yThreshold = firstRender ? 1 : RENDERING_VIEWPORT_THRESHOLD;
  const renderingViewport = pageScrolltop + window.innerHeight + yThreshold;
  const elementTop = placeholder.getBoundingClientRect().top;

  return elementTop < renderingViewport;
};

const createViewportListener = (placeholder, loadFn) => {
  const label = new Date().getTime();
  return () => {
    if (shouldComponentBeFetched(placeholder)) {
      if (!isProduction) console.log('should run only once ', label);
      loadFn();
    }
  };
};

const loadVisibleComponent = (componentImport) => {
  const ComponentLoader = (props) => {
    const placeholderRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const loadedComponent = useRef(null);
    const isFirstRender = useRef(true);

    const loadComponent = async () => {
      if (loadedComponent.current) return;
      const m = await componentImport();
      if (!isProduction) console.log('trigger module loading');
      loadedComponent.current = m.default;
      setIsVisible(true);
    };

    useEffect(() => {
      //component loaded, we no longer need event listeners
      if (loadedComponent.current) return;

      const placeholderDiv = placeholderRef?.current;
      const isForceLoaded = isFirstRender.current && shouldComponentBeFetched(placeholderDiv, true);
      isFirstRender.current = false;

      if (isForceLoaded) {
        if (!isProduction) console.log('force loading');
        //then we'll fetch component right now
        //keep in mind it needs some time as it is fetched from separate bundle
        loadComponent();
      } else {
        //then we'll need to load component when it appers in rendering viewport
        const listener = createViewportListener(placeholderDiv, loadComponent);
        window.addEventListener('scroll', listener);
        if (!isProduction) console.log('scroll listener applied');

        return () => {
          window.removeEventListener('scroll', listener);
        };
      }
    }, [isVisible]);

    if (isVisible && loadedComponent.current) {
      if (!isProduction) console.log('LVC: rendering component');
    }

    return isVisible && loadedComponent ? (
      <loadedComponent.current {...props} />
    ) : (
      <article ref={placeholderRef} style={{ height: '1px' }}></article>
    );
  };

  return ComponentLoader;
};

export default loadVisibleComponent;
