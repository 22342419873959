import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const RedirectWithStatus = ({ from, to, statusCode }) => {
  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) staticContext.statusCode = statusCode;
        return <Redirect from={from} to={to} />;
      }}
    />
  );
};

export const RouteStatus = (props) => {
  const routeProps = { ...props };
  delete routeProps.children;

  return (
    <Route
      {...routeProps}
      render={({ staticContext }) => {
        // we have to check if staticContext exists
        // because it will be undefined if rendered through a BrowserRouter
        if (staticContext) {
          staticContext.statusCode = props.statusCode;
        }

        return props.children;
      }}
    />
  );
};
