import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { setProfile } from '../state/profile/profile';

export const useUserProfileState = () => {
  const dispatch = useDispatch();

  const setReduxProfile = useCallback(
    (profile) => {
      dispatch(setProfile(profile));
    },
    [dispatch]
  );

  return { setReduxProfile };
};
