import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { parseValidJSON } from './parseValidJSON';

const useAdultContentHandlers = () => {
  const [cookies, setCookie] = useCookies(['adultContentAllowed', 'adultContentBlocked']);
  const adultContentAllowed = parseValidJSON(cookies.adultContentAllowed);
  const adultContentBlocked = parseValidJSON(cookies.adultContentBlocked);

  const allowAdultContent = useCallback(() => {
    const expDate = new Date();
    expDate.setFullYear(expDate.getFullYear() + 1);

    setCookie('adultContentAllowed', 'true', {
      expires: expDate,
      path: '/',
    });
  }, [setCookie]);

  const forbidAdultContent = useCallback(() => {
    const expDate = new Date();
    expDate.setFullYear(expDate.getFullYear() + 1);

    setCookie('adultContentAllowed', 'false', {
      expires: expDate,
      path: '/',
    });
  }, [setCookie]);

  const blockAdultContent = useCallback(() => {
    forbidAdultContent();
    const expDate = new Date();
    expDate.setFullYear(expDate.getFullYear() + 1);

    setCookie('adultContentBlocked', 'true', {
      expires: expDate,
      path: '/',
    });
  }, [forbidAdultContent, setCookie]);

  const unblockAdultContent = useCallback(() => {
    allowAdultContent();
    const expDate = new Date();
    expDate.setFullYear(expDate.getFullYear() + 1);

    setCookie('adultContentBlocked', 'false', {
      expires: expDate,
      path: '/',
    });
  }, [allowAdultContent, setCookie]);

  return {
    adultContentAllowed,
    adultContentBlocked,
    allowAdultContent,
    forbidAdultContent,
    blockAdultContent,
    unblockAdultContent,
  };
};

export default useAdultContentHandlers;
