import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setSearchHistory } from '../state/searchHistory/searchHistory';

export const useSearchHistoryInit = () => {
  const dispatch = useDispatch();

  const initSearchHistory = useCallback(
    (searchHistory) => {
      dispatch(setSearchHistory(searchHistory));
    },
    [dispatch]
  );

  useEffect(() => {
    const storedSearchHistory = JSON.parse(localStorage.getItem('searchHistory') || '[]');
    initSearchHistory(storedSearchHistory);
  }, [initSearchHistory]);
};
