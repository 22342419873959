import { setAnonymousLikedProducts } from '../state/anonymousLikedProducts/anonymousLikedProducts';
import { parseValidJSON } from './parseValidJSON';

const convertWishlistItemsToNewFormat = (legacyAnonLikedProds) => {
  const date = new Date();
  const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  const convertedArray = legacyAnonLikedProds.map((productId) => ({ price: null, productId, date: formattedDate }));
  return convertedArray;
};

/**
 * Migrates user profile wishlist related field if needed
 * @param {Object} userProfile
 * @returns null if profile is up to date OR profile object otherwise
 */
export const migrateProfileWishlist = (userProfile) => {
  let profileMutated = false;
  const userProfileMigrated = { ...userProfile };
  if (userProfileMigrated?.likedProducts?.length) {
    //profile liked products has legacy format
    userProfileMigrated.wishlistItems = convertWishlistItemsToNewFormat(userProfileMigrated?.likedProducts);
    delete userProfileMigrated.likedProducts;
    profileMutated = true;
    console.log('MPW: profile liked prods to wishlist items in a new format');
  }
  if (userProfileMigrated?.likedProducts && !userProfileMigrated.likedProducts.length) {
    //profile liked products has unsupported format
    delete userProfileMigrated.likedProducts;
    profileMutated = true;
    console.log('MPW: profile liked prods has unsupported format');
  }
  if (userProfileMigrated?.wishlistItems?.length && typeof userProfileMigrated.wishlistItems[0] == 'string') {
    //profile wishlistItems has legacy format
    userProfileMigrated.wishlistItems = convertWishlistItemsToNewFormat(userProfileMigrated?.wishlistItems);
    profileMutated = true;
    console.log('MPW: profile wishlistItems has legacy format');
  }
  if (profileMutated) {
    return userProfileMigrated;
  } else return null;
};

export const migrateAnonUserWishlist = (dispatch) => {
  //migrate from old style anonymous wishlist to a new one
  const lsItem = localStorage.getItem('likedProds');

  if (lsItem) {
    const legacyAnonLikedProds = parseValidJSON(lsItem);
    const convertedItem = Array.isArray(legacyAnonLikedProds) ? legacyAnonLikedProds : [];
    localStorage.setItem('wishlistItems', JSON.stringify(convertedItem));
    localStorage.removeItem('likedProds');
    console.log('MPW: anon liked prods to wishlistItems');
  }

  const wishlistItemsLS = localStorage.getItem('wishlistItems');
  const legacyWishlistItemsLS = parseValidJSON(wishlistItemsLS);

  if (legacyWishlistItemsLS && typeof legacyWishlistItemsLS[0] === 'string') {
    const convertedArray = convertWishlistItemsToNewFormat(legacyWishlistItemsLS);
    dispatch(setAnonymousLikedProducts(convertedArray));
    localStorage.setItem('wishlistItems', JSON.stringify(convertedArray));
    console.log('MPW: anon wishlst items to new format');
  }
};
