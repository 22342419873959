export const parseFeatureFlags = (search, setCookie) => {
  if (!search?.includes('feature-flag')) return;

  const params = new URLSearchParams(search);
  const flagsToEnable = params.get('enable-feature-flags');

  if (flagsToEnable) setCookie('enabledFeatureFlags', flagsToEnable, { path: '/' });

  const flagsToDisable = params.get('disable-feature-flags');
  if (flagsToDisable === '1') setCookie('enabledFeatureFlags', '', { path: '/' });
  else setCookie('disabledFeatureFlags', flagsToDisable, { path: '/' });
  window.location.href = window.location.origin;
};
